<template>
  <div>
    <vx-card>
      <div v-if="$apollo.loading || updateLoading">
        <p>Cargando...</p>
      </div>
      <div v-else>
        <ValidationObserver
          ref="validateFormFranchiseUpdate"
          v-slot="{ invalid }"
        >
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:150"
                name="Nombre"
              >
                <vs-input
                  class="w-full"
                  label="Nombre"
                  placeholder="Nombre"
                  v-model="negotiation.name"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-6">
              <ValidationProvider
                name="Compañía"
                vid="Compañía"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-select
                  label="Compañía"
                  v-model="negotiation.company"
                  class="w-full"
                  placeholder="Compañía"
                  autocomplete
                >
                  <div
                    class="items-select"
                    v-for="(company, index) in companies"
                    :value="company.id"
                    :key="index"
                  >
                    <vs-select-item
                      :value="company.id"
                      :text="`${company.name}`"
                    />
                  </div>
                </vs-select>
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <label class="flex items-center mb-3">
            <vs-checkbox
              data-test="roundCostCheckbox"
              v-model="negotiation.round_cost"
            >
            </vs-checkbox>
            Redondear costo
          </label>

          <div class="vx-row mt-10">
            <div class="vx-col w-full flex justify-end">
              <vs-button
                class="mr-3 mb-3"
                @click="performMutation"
                :disabled="invalid"
              >
                Guardar
              </vs-button>
              <vs-button
                type="border"
                class="mr-3 mb-3"
                @click="$router.go(-1)"
              >
                Descartar
              </vs-button>
              <vs-button
                v-if="mode == 'edit'"
                class="mr-3 mb-3"
                type="border"
                @click="confirmDelete"
              >
                Eliminar
              </vs-button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { NEGOTIATION, COMPANIES } from '@/graphql/queries';
import {
  CREATE_NEGOTIATION,
  UPDATE_NEGOTIATION,
  DELETE_NEGOTIATION,
} from '@/graphql/mutations';
export default {
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
  },

  data() {
    return {
      negotiation: {
        id: null,
        name: '',
        company: null,
        round_cost: false,
      },
      companies: [],
      updateLoading: false,
    };
  },

  apollo: {
    negotiation: {
      query: NEGOTIATION,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return this.mode == 'create';
      },
      result({ data }) {
        this.negotiation = {
          id: data.negotiation.id,
          name: data.negotiation.name,
          company: data.negotiation.company.id,
          round_cost: data.negotiation.round_cost,
        };
      },
    },
    companies: {
      query: COMPANIES,
      result({ data }) {
        this.companies = data.companies;
      },
    },
  },

  methods: {
    performMutation() {
      if (this.mode == 'edit') {
        this.updateNegotiation();
      } else {
        this.createNegotiation();
      }
    },

    updateNegotiation() {
      this.updateLoading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_NEGOTIATION,
          variables: {
            input: {
              id: this.negotiation.id,
              name: this.negotiation.name,
              company: this.negotiation.company,
              round_cost: this.negotiation.round_cost,
            },
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Negociación actualizada!',
            text: 'La negociación fue actualizada correctamente.',
          });
          this.$router.push(`/configuracion/negociaciones`);
          this.updateLoading = false;
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Negociación no actualizada',
            text: 'La negociación no fue actualizada.',
          });
          this.updateLoading = false;
        });
    },

    createNegotiation() {
      this.updateLoading = true;
      this.$apollo
        .mutate({
          mutation: CREATE_NEGOTIATION,
          variables: {
            input: {
              id: this.negotiation.id,
              name: this.negotiation.name,
              company: this.negotiation.company,
              round_cost: this.negotiation.round_cost,
            },
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Negociación creada!',
            text: 'La negociación fue creada correctamente.',
          });
          this.$router.push(`/configuracion/negociaciones`);
          this.updateLoading = false;
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Negociación no creada',
            text: 'La negociación no fue creada.',
          });
          this.updateLoading = false;
        });
    },

    confirmDelete() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirmar eliminación`,
        text: `¿Seguro que quieres eliminar la Negociación?`,
        accept: this.deleteNegotiation,
        acceptText: 'Eliminar',
        cancelText: 'Cancelar',
      });
    },

    deleteNegotiation() {
      this.updateLoading = true;
      this.$apollo
        .mutate({
          mutation: DELETE_NEGOTIATION,
          variables: {
            id: this.negotiation.id,
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Negociacón eliminada!',
            text: 'La tarifa por área fue eliminada correctamente.',
          });
          this.$router.push(`/configuracion/negociaciones`);
          this.updateLoading = false;
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Negociacón no eliminada',
            text: 'La tarifa por área no fue eliminada.',
          });
          this.updateLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/apps/simple-calendar.scss';

.simple-calendar {
  &__swatches-custom-trigger {
    height: 41px;
    width: 100%;
    border: 1px solid #d7e2e8;
  }
}
.vue-swatches__trigger__wrapper {
  width: 100%;
}
</style>
